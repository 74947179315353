import React, {useEffect, useState} from "react";
import HomeCtaSection from "../home/home-cta-section";
import {processParam, statusTranslator} from "../shared";
import useSWR from "swr";
import {appUrls} from "../../util/api_constant";
import {getDataByParams, post} from "../../services/BaseService";
import {useForm} from "react-hook-form";
import {formatDateToString} from "../../util/helper";
declare let UIkit: any;
let tomorrow: Date = new Date();
 tomorrow.setDate(tomorrow.getDate() + 1);
 
 const processServiceAndBillingTypes = (pricing: {id:number, basePrice: number, serviceType: string, billingType: string}[]) => {
   let items:{type: string, billing: {id:number, name: string, price: number }[]}[] = [];
   pricing.forEach(p => {
       if (p.basePrice > 0){
           const index = items.findIndex(i => i.type === p.serviceType);
           if (index >= 0){
               if (!items[index].billing.some(i => i.name === p.billingType)){
                   items[index].billing.push({name: p.billingType, price: p.basePrice, id: p.id});
               }
           }else {
               items.push({type: p.serviceType, billing:[{name: p.billingType, id: p.id, price: p.basePrice}]});
           }
       }
   })
     return items;
 }
const EquipmentDetails = ({}:{}) => {
    const [params, _] = useState<any>(processParam({defaultValue:`slug=all`}));
    const {data:equipment} = useSWR<any>((params && params.slug)?`${appUrls.equipments}/${params.slug}`:null, url => getDataByParams(url));
    const {register, handleSubmit, setValue, formState: {errors}} = useForm<any>({mode: 'all'});
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [startDate, setStartDate] = useState<Date>(tomorrow);
    const [endDate, setEndDate] = useState<Date>(tomorrow);
    const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
    const [typeItems, setTypeItems] = useState<{type: string, billing: { id: number, name: string, price: number }[]}[]>((equipment && equipment.pricing)?processServiceAndBillingTypes(equipment.pricing):[]);
    const [selectedTypeItem, setSelectedTypeItem] = useState<{type: string, billing: { id: number, name: string, price: number }[]}>();

    const onSubmit = (data: any) => {
        if (selectedTypeItem){
            const index = selectedTypeItem?.billing.findIndex(i => i.name === data['billingType'])
            if (index >= 0){
                const body : any = {
                    customer: {firstName: data['fullName'], phoneNumber: data['phoneNumber'].replace(/\s/g,''), username:data['email']},
                    startDate: startDate,
                    endDate: endDate,
                    transport: (data['transport'] === 'YES'),
                    notes: data['notes'],
                    destination: data['destination'],
                    equipmentPricing: {id: selectedTypeItem.billing[index].id}
                }

                setIsSubmitting(true);
                post(appUrls.reservations, body)
                    .then(() => {
                        if (UIkit){
                            UIkit.notification({message: `<span uk-icon='icon: check'></span> Félicitation ! <br> Votre demande a été envoyée avec succès`,  status: 'success'});
                        }
                        setIsSubmitted(true);
                        if (typeof window !== "undefined")
                            window.scroll(0, 0);

                        setValue('fullName', undefined);
                        setValue('email', undefined);
                        setValue('phoneNumber', undefined);
                        setValue('destination', undefined);
                        setValue('equipment', undefined);
                        setValue('notes', undefined);
                        setValue('transport', "YES");
                        setStartDate(tomorrow);
                        setEndDate(tomorrow);
                        setIsSubmitting(false);
                    }).catch(() => {
                    setIsSubmitting(false);
                    if (UIkit){
                        UIkit.notification({message: `<span uk-icon='icon: close'></span> Ooops!. <br> Queque chose s'est mal passé. Vérifiez les données saisies et réessayez ultérieurement !`,  status: 'danger'});
                    }
                })
            }
        }
    }
    useEffect(() => {
        if (equipment && equipment.pricing) {
            const items = processServiceAndBillingTypes(equipment.pricing);
            setTypeItems(items);
            if (items[0]) {
                setSelectedTypeItem(items[0]);
            }
        }
    }, [equipment])

    const onServiceTypeChange = (value: string) => {
        const index = typeItems.findIndex(i => i.type === value);
        if (index >= 0)
            setSelectedTypeItem(typeItems[index]);
    }
    const onBillingTypeChange = (value: string) => {
        /*if (selectedTypeItem){
            const index = selectedTypeItem.billing.findIndex(i => i.name === value);
            if (index >= 0){
                switch (selectedTypeItem.billing[index].name) {
                    case "HOUR":
                        return;
                    case "MONTHLY":
                        return;
                    case "DAILY":
                        return;
                    case "":
                        return;
                    default:
                        return;
                }
            }
        }*/
    }
    return <>
        <div className="page-head">
            <div className="page-head__bg" style={{backgroundImage: "url(/assets/img/bg/bg_categories.jpg)"}}>
                <div className="page-head__content" data-uk-parallax="y: 0, 100">
                    <div className="uk-container">
                        <div className="header-icons"><span></span><span></span><span></span></div>
                        <div className="page-head__title"> {equipment?equipment.name:'Equipment Inconnu'}</div>
                        <div className="page-head__breadcrumb">
                            <ul className="uk-breadcrumb">
                                <li><a href="/">Accueil</a></li>
                                <li><a href="/equipments?cat=all">Equipments</a></li>
                                <li><span>{equipment?equipment.name:'Equipment Inconnu'}</span></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="page-content">
            <div className="uk-section-large uk-container">
                {
                    (equipment && equipment.id)?
                        <div className="uk-grid" data-uk-grid="">
                            <div className="uk-width-2-3@m">
                                <div className="equipment-detail">
                                    <div className="equipment-detail__gallery">
                                        <div data-uk-slideshow="min-height: 300; max-height: 430">
                                            <div className="uk-position-relative">
                                                <ul className="uk-slideshow-items uk-child-width-1-1"
                                                    data-uk-lightbox="animation: scale">

                                                    {
                                                        (equipment.equipmentAssets)?
                                                            [{path:equipment.image}, ...equipment.equipmentAssets.filter((item: any) => item.type==="IMAGE")].map((asset: any) => (
                                                                <li key={`${asset.path}`} style={{cursor:'pointer'}}>
                                                                    <a href={asset.path}><img className="uk-width-1-1" src={asset.path} alt="img-gallery" data-uk-cover="" /></a></li>
                                                            )): (equipment.image)?<li>
                                                                <a href={equipment.image}><img className="uk-width-1-1" src={equipment.image} alt="img-gallery" data-uk-cover="" /></a></li>: null
                                                    }

                                                </ul>
                                                <a className="uk-position-center-left uk-position-small uk-hidden-hover"
                                                   href="#" data-uk-slidenav-previous=""  data-uk-slideshow-item="previous"></a><a
                                                className="uk-position-center-right uk-position-small uk-hidden-hover" href="#"
                                                data-uk-slidenav-next="" data-uk-slideshow-item="next"></a>
                                            </div>
                                            <div className="uk-margin-top" data-uk-slider="" >
                                                <ul className="uk-thumbnav uk-slider-items uk-grid uk-grid-small uk-child-width-1-2 uk-child-width-1-3@s uk-child-width-1-4@m uk-child-width-1-5@l">
                                                    {
                                                        (equipment.equipmentAssets)?
                                                            [{path:equipment.image}, ...equipment.equipmentAssets].map((asset: any, index: number) => (
                                                                <li key={`thumb_${asset.path}`} data-uk-slideshow-item={(index)}>
                                                                    <a href={asset.path}><img src={asset.path} alt="img-gallery" /></a></li>
                                                            )): (equipment.image)?
                                                                <li data-uk-slideshow-item={0}><a href={equipment.image}><img
                                                                    src={equipment.image}
                                                                    alt="img-gallery" /></a></li>: null
                                                    }
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="equipment-detail__title">{equipment.name}</div>
                                    <div className="equipment-detail__location"><span data-uk-icon="location"></span>
                                        {equipment.address?equipment.address:'Adresse non renseignée'}
                                    </div>
                                    {
                                        (equipment.equipmentAssets && equipment.equipmentAssets.filter((item: any) => item.type === "DOC").length > 0)?
                                            <div>
                                            {
                                                equipment.equipmentAssets.filter((item: any) => item.type === "DOC").map((item: any) => (
                                                    <div className="equipment-detail__btns" key={item.path} >
                                                        <a href={item.path}><i className="fas fa-file-pdf"></i>{item.name}</a><br/>
                                                    </div>
                                                ))
                                            }
                                        {/*<a href="#!"><i className="fas fa-file-pdf"></i>View or Download Brochure</a>
                                        <a href="#!"><i className="fas fa-star"></i>Favourite This Equipment</a>*/}
                                        </div>: null
                                    }
                                    <div className="equipment-detail__desc uk-margin-top">
                                        <div className="section-title">
                                            <div className="uk-h2">Description</div>
                                        </div>
                                        <p>{equipment.description}</p>
                                    </div>
                                    <div className="equipment-detail__specification">
                                        <div className="section-title">
                                            <div className="uk-h2">Specification</div>
                                        </div>
                                        {(equipment.specifications) ?
                                            <table className="uk-table uk-table-striped">
                                                <tbody className="tbody">
                                                {
                                                    equipment.specifications.map((sp: any) => (<tr key={sp.label}><td>{sp.label}:</td><td>{sp.value}</td></tr>))
                                                }
                                                </tbody>
                                            </table>: null
                                        }

                                    </div>
                                </div>
                            </div>
                            <div className="uk-width-1-3@m">
                                <form onSubmit={handleSubmit(onSubmit)} className={"uk-form-stacked"}>
                                <div className="equipment-order">
                                    <div className="equipment-order__price"><span> {Intl.NumberFormat('fr-FR').format(equipment.equipmentType === "SALE"?equipment.sellingPrice:equipment.price)}<small>F CFA {(equipment.equipmentType === "RENT")?`par ${statusTranslator(equipment.billingType, false)}`:''}</small></span></div>
                                    <div className="equipment-order__form">
                                        <div className="uk-margin">
                                            <input {...register(`fullName`,{required: true})}
                                                   className={`uk-input uk-form-controls uk-form-large ${errors[`fullName`] ? 'uk-form-danger' : ''}`}
                                                   type="text"
                                                   placeholder="Votre nom complet *"/>
                                        </div>
                                        <div className="uk-margin">
                                            <input {...register(`phoneNumber`,{required: true})}
                                                   className={`uk-input uk-form-controls uk-form-large ${errors[`phoneNumber`] ? 'uk-form-danger' : ''}`}
                                                   type="text"
                                                   placeholder="Votre numéro de téléphone *"/>
                                        </div>
                                        <div className="uk-margin">
                                            <input {...register(`email`,{required: true})}
                                                   className={`uk-input uk-form-controls uk-form-large ${errors[`email`] ? 'uk-form-danger' : ''}`}
                                                   type="email"
                                                   placeholder="Votre adresse mail *"/>
                                        </div>
                                        <div className="uk-margin">
                                            <label className="uk-form-label">Date de début <span style={{color:'red'}}>*</span></label>
                                            <input {...register(`startDate`,{required: true})}
                                                value={formatDateToString(startDate)}
                                                   min={formatDateToString(new Date())}
                                                   onChange={event => {
                                                       setStartDate(new Date(event.target.value));
                                                       setEndDate(new Date(event.target.value));
                                                   }}
                                                   className={`uk-input uk-form-controls uk-form-large ${errors[`startDate`] ? 'uk-form-danger' : ''}`}
                                                   type="date"
                                                   placeholder="Date(prevue) de début *"/>
                                        </div>
                                        <div className="uk-margin">
                                            <label className="uk-form-label">Date de fin <span style={{color:'red'}}>*</span></label>
                                            <input {...register(`endDate`,{required: true})}
                                                value={formatDateToString(endDate)}
                                                   min={formatDateToString(startDate)}
                                                   onChange={event => {
                                                       setEndDate(new Date(event.target.value));
                                                   }}
                                                   className={`uk-input uk-form-controls uk-form-large ${errors[`endDate`] ? 'uk-form-danger' : ''}`}
                                                   type="date"
                                                   placeholder="Date(prevue) de fin"/>
                                        </div>
                                        <div className="uk-margin">
                                            <input {...register(`destination`,{required: true})}
                                                   className={`uk-input uk-form-controls uk-form-large ${errors[`destination`] ? 'uk-form-danger' : ''}`}
                                                   type="text"
                                                   placeholder="Destination de l'équipement *"/>
                                        </div>
                                        <div className="uk-margin">
                                            <label className="uk-form-label">Type de travaux <span style={{color:'red'}}>*</span></label>
                                            <select {...register(`serviceType`,{required: true})}
                                                    className={`uk-select uk-form-controls uk-form-large ${errors[`serviceType`] ? 'uk-form-danger' : ''}`}
                                                    onChange={(event: any) => {onServiceTypeChange(event.target.value)}}
                                            >
                                                {typeItems.map(t => (<option key={t.type} value={t.type}>{statusTranslator(t.type, false)}</option>))}
                                                {/*<option value={"MINE"}>Travaux Miniers</option>
                                                <option value={"TP"}>Bâtiment et Travaux Publics</option>
                                                <option value={"TRANSPORT"}>Transport</option>*/}
                                            </select>
                                        </div>
                                        <div className="uk-margin">
                                            <label className="uk-form-label">Choix de facturation <span style={{color:'red'}}>*</span></label>
                                            <select {...register(`billingType`,{required: true})}
                                                    className={`uk-select uk-form-controls uk-form-large ${errors[`billingType`] ? 'uk-form-danger' : ''}`}
                                                    onChange={(event: any) => {onBillingTypeChange(event.target.value)}}
                                            >
                                                {(selectedTypeItem && selectedTypeItem.billing.length > 0)?
                                                    selectedTypeItem.billing.map(b => (
                                                        <option key={b.name} value={b.name}>Par{statusTranslator(b.name, false)} ({b.price} FCFA)</option>
                                                    )) : null
                                                }
                                                {/*<option value={"HOURLY"}>Par Heure</option>
                                                <option value={"DAILY"}>Par Jour</option>
                                                <option value={"TOTAL"}>Par Production</option>
                                                <option value={"COUNTER"}>Par Compteur</option>*/}
                                            </select>
                                        </div>
                                        <div className="uk-margin">
                                            <div className="uk-form-label">Voulez-vous que <strong>SME</strong> transporte l'équipement pour vous  <span style={{color:'red'}}>*</span>?</div>
                                            <select {...register(`transport`,{required: true})}
                                                    className={`uk-select uk-form-controls uk-form-large ${errors[`transport`] ? 'uk-form-danger' : ''}`}
                                            >
                                                <option value={"YES"}>Oui - SME peut le tranporter</option>
                                                <option value={"NO"}>Non - je m'en occupe</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="equipment-order-total">
                                    <div className="uk-margin">
                                        <label className="uk-form-label">Plus d'informations</label>
                                        <textarea
                                            {...register(`notes`,{required: false})}
                                            className={`uk-textarea ${errors[`notes`] ? 'uk-form-danger' : ''}`}/>
                                    </div>
                                    <p className="uk-text-justify">
                                        Nous vous enverrons une facture proformat qui récapitule les informations renseignées ci-dessus avec les montants totaux.
                                        Merci donc renseigner les bonnes informations.
                                    </p>
                                    {/*<ul>
                                        <li><span>Nombre de jours</span><span>{diffDates(startDate, endDate)}</span></li>
                                        <li><span>Taux horaire</span><span>---</span></li>
                                        <li><span>Prix journalier</span><span>---</span></li>
                                        <li><span>Total</span><span>{Intl.NumberFormat('fr-FR').format(total)}</span></li>
                                    </ul>*/}

                                    <button disabled={isSubmitting} className="uk-button uk-button-large uk-width-1-1" type="submit">
                                        {isSubmitting?<span><div data-uk-spinner="ratio: 2"/>Envoi en cours ...</span>:
                                            <span>Réservez</span>}
                                        {
                                            (!isSubmitting)?<img src="/assets/img/icons/arrow.svg" alt="arrow" data-uk-svg=""/>:null
                                        }
                                    </button>
                                </div>
                                </form>
                            </div>
                        </div>:
                        <div className="uk-text-center">
                            <h3>Equipement introuvable !</h3>
                        </div>
                }
            </div>
        </div>
        <HomeCtaSection/>
    </>
}

export default EquipmentDetails;